body {
  margin: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

img,
a {
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  -webkit-touch-callout: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
  draggable: false;
}

.policeInfoShelf {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}

.paddingLikeComment {
  padding-right: 37px;
}

.backgroundGrey {
  height: 100%;
  min-height: calc(100vh - 50px);
  background-color: #e3e3e3;
}

.fixWidth {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.backgroundShelf {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.containerTopShelf {
  padding-bottom: 22px;
  padding-left: 24px;
  padding-right: 24px;
  text-shadow: 0px 2.08333px 5px rgba(0, 0, 0, 0.25),
    0px 0px 20px rgba(0, 0, 0, 0.2);
}

.containerTopShelf-loading {
  padding-bottom: 22px;
  padding-left: 24px;
  padding-right: 24px;
}

.backgroundTopBook {
  height: 307px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
}

.containerTopBook {
  z-index: 1;
  display: flex;
  flex-direction: row;
  padding: 32px 32px;
}

.bookScreen-iconAuthor {
  height: 20px;
  width: 20px;
}

.blurCover {
  width: 100%;
  height: 100%;
  background: rgba(37, 37, 37, 0.5) no-repeat center;
  background-blend-mode: multiply;
  background-size: cover;
  filter: blur(36px);
}

.container {
  position: absolute;
  overflow: hidden;
  width: 900px;
  height: 307px;
  background: rgba(37, 37, 37, 0.5);
}

.containerInfoBook {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 38px;
  padding-top: 15px;
  filter: drop-shadow(0px 2.08333px 5.20833px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 0px 20.8333px rgba(0, 0, 0, 0.1));
}

.commentScreen-header {
  display: none;
  background-color: #252525;
  width: 100%;
  justify-content: center;
}

.header-title {
  color: white;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  padding: 7px;
  text-align: center;
}

.commentScreen-comment {
  background-color: white;
  padding: 16px 24px 0;
  border-bottom: solid 1px #b2b2b2;
}

.commentScreen-comment-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.comment-header-picture {
  height: 56px;
  width: 56px;
  margin-right: 12px;
  display: block;
  border-radius: 28px;
}

.comment-header-userinfo {
  width: 100%;
}

.comment-header-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.comment-header-usertext {
  margin: 0;
  font-family: Fira Sans, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 161%;
  color: #252525;
}

.comment-header-creationDate,
.comment-header-updateDate {
  font-style: italic;
  color: #b2b2b2;
}

.comment-option {
  width: 16px;
  height: 16px;
}

.commentScreen-title {
  margin: 16px 0;
  font-family: Vollkorn, serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 132%;
  color: #252525;
}

.commentScreen-content {
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  line-height: 161%;
  color: #252525;
}

.comment-header-updateDate {
  font-size: 14px;
  line-height: 161%;
  margin: 16px 0;
  font-family: Fira Sans, sans-serif;
  font-weight: 600;
}

.comment-line {
  height: 1px;
  background-color: #b2b2b2;
  border: none;
  margin: 0;
}

.commentScreen-comment-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
}

.comment-footer-stats {
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  line-height: 160%;
  color: #595959;
  margin: 0;
}

.comment-footer-interaction {
  margin: 0 12px;
}

.commentScreen-comment-loading {
  background-color: white;
  padding: 150px 0;
}

.commentScreen-reaction {
  padding: 16px 24px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px #b2b2b2;
}

.reaction-userinfo {
  display: flex;
  flex-direction: row;
}

.small-people-picture,
.sendComment {
  height: 32px;
  width: 32px;
  display: block;
}

.reaction-usertext {
  margin-left: 8px;
}

.reaction-username,
.reaction-text {
  font-family: Fira Sans, sans-serif;
  font-size: 13px;
  line-height: 18px;
  color: #252525;
  margin: 0;
}

.reaction-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.reaction-date {
  font-family: Fira Sans, sans-serif;
  font-style: italic;
  font-size: 10px;
  line-height: 160%;
  color: #b2b2b2;
  margin: 0;
}

.write-comment-container {
  padding: 24px 0 72px;
}

.write-comment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background-color: white;
  width: 95%;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08), 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.write-comment-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.write-comment-text {
  margin: 0 0 0 8px;
  font-family: Fira Sans, sans-serif;
  font-style: italic;
  font-size: 13px;
  line-height: 155%;
  color: #b2b2b2;
}

.semibold {
  font-weight: 600;
}

.blur {
  filter: blur(4px);
}

.modal-police-rgpd {
  font-family: Fira Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  color: #252525;
}

.modal-container-text-rgpd {
  padding-left: 8px;
}

.infoBookPolice {
  font-family: Fira Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #e3e3e3;
}

.typeBookPolice {
  font-family: Fira Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}

.clil-police-loading {
  background-color: #d1c8b3;
}

.clilContainer {
  display: flex;
  align-items: center;
  padding: 4px 5px 4px 4px;
  border-radius: 2.08333px;
  margin-right: auto;
}

.clilContainer-loading {
  width: 30%;
  display: flex;
  padding: 4px 5px 4px 4px;
  border-radius: 2.08333px;
  margin-right: auto;
}

.backgroundShelfAuthor {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.download {
  margin-right: 16px;
  background: #ffffff;
  border-radius: 16px;
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-left: 16px;
  width: 200px;
}

.titleShelf {
  width: 80%;
  font-family: Vollkorn, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 130%;
  color: #ffffff;
}



.titleShelf-loading-up {
  opacity: 0.75;
  border-bottom: 24px solid #ffffff;
  width: 54%;
  align-self: center;
  margin-bottom: 16px;
}

.titleShelf-loading-down {
  opacity: 0.75;
  border-bottom: 24px solid #ffffff;
  width: 30%;
  margin-bottom: 21px;
}

.titleBook-loading-up {
  opacity: 0.75;
  border-bottom: 24px solid #ffffff;
  width: 80%;
  margin-bottom: 16px;
}

.titleBook-loading-down {
  opacity: 0.75;
  border-bottom: 24px solid #ffffff;
  width: 60%;
  margin-bottom: 21px;
}

.ownershelf-loading-up {
  border-bottom: 16px solid #e3e3e3;
  width: 91%;
  margin-bottom: 8px;
}

.ownershelf-loading-down {
  border-bottom: 16px solid #e3e3e3;
  width: 52%;
}

.infoShelf {
  display: flex;
  align-items: center;
}

.bookScreen-authorLog-Police {
}

.containerInfoShelf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backgroundContent {
  min-height: 100vh;
  flex-direction: column;
  background-color: #f7f7f7;
}

.backgroundOpacityListBookInShelf {
  position: absolute;
  width: 900px;
  height: 24px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.1;
}

.roundPictureProfil {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.roundPictureProfilBottomBar {
  border-radius: 50%;
  height: 52px;
  width: 52px;
}

.ownerShelf {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
  margin-top: 16px;
  height: 32px;
}

.nameOwnerPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #252525;
}

.ownerpanel-police-loading {
  background-color: #e3e3e3;
}

.containerNameOwner {
  display: flex;
}

.topshelf-info-nbbook {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  padding-right: 8px;
}

.topshelf-info-public {
  padding-left: 4px;
  padding-right: 9px;
}

.topshelf-info-collab {
  padding-left: 4px;
  padding-right: 10px;
}

.topshelf-icon-nbbook {
  margin-right: 7px;
}

.backgroundshelf-police-loading {
  background-color: #ffffff;
  opacity: 0.5;
}

.backgroundbook-police-loading {
  background-color: #e3e3e3;
  opacity: 0.5;
  color: #e3e3e3;
  height: 10%;
  width: 30%;
}

.ownershelf-police-loading {
  background-color: #ffffff;
  opacity: 0.5;
}

.topshelf-icon-public {
  padding-right: 8px;
}

.bookscreen-peoplefollow-allcard-container {
  padding-left: 48px;
}

.nbFollowersPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* GENERAL/GRIS MOYEN */

  color: #595959;
}

.descShelfPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */

  color: #252525;
}

.containerDescShelf {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 13px;
}

.likeComPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 160%;
  color: #595959;
}

.workPicture {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  height: 183px;
  width: 128px;
  object-fit: cover;
}

.workPicture-default {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  height: 183px;
  width: 128px;
}

.shelf-book-container {
  padding-right: 16.8px;
  padding-bottom: 12px;
}

.notfoud-container {
  display: flex;
  height: calc(100vh - 50px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
}

.notfound-title-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #252525;
}

.notfound-text-police {
  font-family: Fira Sans;
  font-style: normal;
  font-size: 50px;
  line-height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #252525;
}

.className {
  object-fit: cover;
  background-color: #252525;
}

.containerListBook {
  width: auto;
  padding-left: 24px;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.shelfscreen-booklist-loadingcover {
  background-color: #e3e3e3;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.bottomBar {
  height: 97px;
  bottom: 0px;
  background: rgba(37, 37, 37, 0.6);
  backdrop-filter: blur(30px);
  width: 100%;
  display: flex;
  justify-content: center;
}

.bottomBarBook {
  height: 97px;
  bottom: 0px;
  background: rgba(37, 37, 37, 0.6);
  backdrop-filter: blur(30px);
  width: 100%;
  display: flex;
  justify-content: center;
}

.fullTopBar {
  display: flex;
  background-color: #252525;
  position: fixed;
  top: 0px;
  z-index: 1000;
  width: 100%;
}

.playStore {
  height: 30px;
  width: 100px;
  padding-right: 9px;
}
.appleStore {
  height: 30px;
  width: 100px;
}

.topBar {
  height: 50px;
  width: 900px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.topBar-store {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 200px;
}

.topBar-store-text {
  font-family: Fira Sans, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  margin: 0;
}

.topBar-store-icon {
  height: 28px;
  width: 28px;
}

.containerBottomBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.containerTextBottomBar {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 91px;
}

.titleBottomBarPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
}

.bookscreen-cover-loading {
  height: 60px;
  width: 83.33px;
}

.textBottomBarPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  max-width: 500px;
  width: auto;
}

.closeBottomBar {
  position: absolute;
  right: 0%;
  padding-right: 8px;
  padding-top: 8px;
  cursor: pointer;
}

.followButton {
  width: 122px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 9px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 2.08333px 5px rgba(0, 0, 0, 0.18))
    drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  border-radius: 20px;
}

.positionPopularity {
  text-align: center;
  padding-bottom: 32.6px;
}

.authorNamePolice {
  font-family: Fira Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-align: left;
  color: #252525;
}

.publishYearPolice {
  font-family: Fira Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #b2b2b2;
}

.containerAuthorPanel {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  width: auto;
}

.separateLine {
  border-bottom: 1px solid #e3e3e3;
  width: auto;
  margin-left: 32px;
  margin-right: 32px;
  align-self: center;
}

.popularityPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #252525;
}

.resumeTitlePolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #252525;
  display: flex;
}

.containerPopularity {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.containerTag {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;
}

.circleTag {
  border: 1.6px solid;
  box-sizing: border-box;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nbTag {
  border: 1.6px solid #f7f7f7;
  width: auto;
  height: 25.6px;
  min-width: 40px;
  border-radius: 16px;
  position: absolute;
  margin-left: 60px;
  top: 495px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerTextTag {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  padding-top: 16px;
}

.nbTagPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14.4px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 1px 8px;
}

.bookScreen-authorLog-container {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3e3e3;
  border-radius: 8px;
  margin-right: 16px;
}

.containerAllTags {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.defaultProfile {
  background: #252525;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.55);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textTagsPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.containerResume {
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
}

.bookscreen-similarbook-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 32px;
  padding-top: 24px;
  padding-bottom: 16px;
}

.bookscreen-sameauthor-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 32px;
  padding-top: 23px;
  padding-bottom: 16px;
}

.bookscreen-comment-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px;
  padding-top: 16px;
}

.bookscreen-comment-icon-police {
  height: 40px;
  width: 40px;
}

.bookscreen-comment-icon-container {
  padding-right: 8px;
}

.bookscreen-similarbook-cover-style {
  height: 171px;
  width: 120px;
}

.bookscreen-similarbook-allcard-container {
  padding-left: 32px;
}

.bookscreen-shelf-allcard-container {
  padding-left: 12px;
}

.bookscreen-shelf-cover-style {
  height: 103px;
  width: 72px;
}

.bookscreen-similarbook-listbook-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 16px;
}

.bookscreen-shelf-listbook-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 11.86px;
}

.bookscreen-similarbook-container {
  padding-bottom: 25px;
}

.bookscreen-similarbook-scrollmenu-container {
  margin-left: 10px;
}

.bookscreen-shelf-card-container {
  height: 199px;
  width: 362px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.bookscreen-shelf-listshelf-container {
  margin-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.bookscreen-shelf-picture-style {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.bookscreen-shelf-owner {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 0 7px;
}

.bookscreen-shelf-countbook-padding {
  padding-left: 5px;
}

.bookscreen-shelf-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 14px;
}

.bookscreen-shelf-info-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}

.bookscreen-shelf-title-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-shadow: 0px 2.41333px 6.03333px rgba(0, 0, 0, 0.08),
    0px 0px 24.1333px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.bookscreen-shelf-header-container {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 16px 12px;
}

.bookscreen-shelf-header-backgroundpicture {
  height: 120px;
  width: 362px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  background: linear-gradient(
    179.14deg,
    rgba(37, 37, 37, 0.5) 0.42%,
    rgba(37, 37, 37, 0.15) 98.95%
  );
  background-size: cover;
  border-radius: 4.82667px 4.82667px 0px 0px;
}

.resumePolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: #252525;
}

.ReadMorePolice {
  font-family: Fira Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  color: #b2b2b2;
}
.flexProcurer {
  display: flex;
  justify-content: flex-end;
}

.containerProcurer {
  position: absolute;
  top: 317px;
  padding-right: 32px;
  /* padding-left: calc(900 * 0.8576px); */
  z-index: 1;
}

.circleProcurer {
  background: #ffae4a;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reducedIconProcurer {
  width: 55%
}

.downloadPolice {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #000000;
  padding: 4px 10px;
}

.containerCommentInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.downloadBottomBarMin {
  background: #ffffff;
  border-radius: 16px;
  width: 220px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.downloadPoliceBottomMin {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #252525;
}

.plusTardBottomBarPolice {
  font-family: Fira Sans;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

.plusTardBottomBarContainer {
  padding-top: 12px;
  padding-bottom: 20px;
}

.sizeBookCover {
  width: 170px;
  height: 243px;
}

.sizeBookCover-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerBookCover {
  display: flex;
  align-items: flex-end;
}

.bookscreen-peoplefollow-picture-style {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.bookscreen-comment-picture-style {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.bookscreen-comment-info-container {
  padding-left: 16px;
}

.bookscreen-peoplefollow-nickname-police {
  font-family: Vollkorn;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #252525;
  display: flex;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.bookscreen-peoplefollow-listpeople-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
  padding-right: 46px;
}

.bookscreen-comment-card-container {
  width: auto;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0px 16px;
  padding-top: 16px;
  padding-bottom: 13px;
}

.bookscreen-shelf-icon-container {
  background: #252525;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.bookscreen-comment-listcomment-container {
  padding-right: 16px;
  margin-bottom: 32px;
  margin-top: 16px;
}

.bookscreen-comment-list-container {
  padding-top: 16px;
}

.bookscreen-comment-content-container {
  display: flex;
  flex-direction: column;
  width: 318px;
  padding-top: 8px;
}

.bookscreen-comment-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.bookscreen-comment-footer-space {
  padding-left: 14px;
  padding-right: 20px;
  padding-top: 5px;
}

.bookscreen-comment-author-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bookscreen-comment-content-title-police {
  font-family: Vollkorn;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 161%;
  color: #000000;
}

.bookscreen-comment-content-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: #252525;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.bookscreen-comment-footer-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #595959;
}

.bookpeople-comment-tags-style {
  height: 12px;
}

.bookpeople-comment-tag-container {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 3px;
}

.bookpeople-comment-tags-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bookscreen-comment-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bookscreen-comment-author-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 161%;
  color: #252525;
}

.bookscreen-comment-date-police {
  font-family: Fira Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 161%;
  color: #b2b2b2;
  padding-left: 10px;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/** Modal style **/

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.modal-rgpd-content-container {
  margin-top: 16px;
  max-width: 550px;
}

.modal-rpgd-content-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  opacity: 0.9;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.55);
}

.modal {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  max-width: 500px;
  width: 360px;
  height: auto;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  padding-top: 16px;
}

.modal-header-police {
  font-family: Vollkorn;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #000000;
}

.modal-header-container {
  max-width: 200px;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 12px;
}

.modal-body-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #252525;
}

.modal-body-container {
  max-width: 248px;
  display: flex;
  align-items: center;
  padding-bottom: 14px;
}

.modal-container-store {
  padding-bottom: 34px;
}

.modal-container-form {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
}

.modal-container-textfield {
  display: flex;
  padding-bottom: 26px;
}

.modal-textfield-police {
  border: none;
  outline: none;
  width: 100%;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
}

.modal-toggle-rgpd {
  border: 2px solid #595959;
  box-sizing: border-box;
  border-radius: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  cursor: pointer;
}

.modal-container-rgpd {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  align-items: center;
}

.modal-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 296px;
  height: 36px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  cursor: pointer;
}

.modal-submit-button-validate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 296px;
  height: 36px;
  border-radius: 20px;
  cursor: pointer;
  background: #89da8e;
}

.modal-submit-button-disable {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 296px;
  height: 36px;
  border: 1px solid #595959;
  box-sizing: border-box;
  border-radius: 20px;
}

.modal-submit-police {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.modal-submit-police-disable {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #595959;
}

.modal-container-submit {
  display: flex;
  flex-direction: row;
}

.modal-postsend-container {
  padding-top: 6px;
  padding-bottom: 20px;
  text-align: center;
}

.modal-postsend-police-1 {
  font-family: Fira Sans;
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #b2b2b2;
}

.modal-postsend-police-2 {
  font-family: Fira Sans;
  font-weight: 700;
  font-size: 14px;
  line-height: 19.6px;
  color: #595959;
  cursor: pointer;
}

.bottombar-openapp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookcover-default-title-police {
  font-family: Volkorn;
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #595959;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.bookcover-default-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookcover-default-border {
  width: 85%;
  height: 90%;
  border: 1px solid;
  border-color: #595959;
  display: flex;
  align-items: center;
  justify-content: center;
}

.provider-container {
  display: flex;
  align-items: center;
  padding: 16px 32px;
}

.provider-text {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  padding-right: 6px;
  color: #595959;
}

.st0{fill:none;stroke:#D3CCB0;stroke-width:5;stroke-miterlimit:10;}
.st1{fill:#595959;}
.st2{fill:#FFFFFF;}

@media (max-width: 900px) {
  .topBar {
    width: 100%;
  }
  .bookScreen-iconAuthor {
    height: 15px;
    width: 15px;
  }

  .container {
    width: 100%;
  }
  .fixWidth {
    max-width: 100%;
  }

  .backgroundOpacityListBookInShelf {
    max-width: 100%;
  }
  .containerTag {
    padding: 0px 3.5vw;
  }
}

@media (max-width: 720px) {
  .logo {
    width: initial;
  }

  .bottomBar {
    height: auto;
    width: 100%;
  }

  .bottomBarBook {
    height: auto;
    width: 100%;
  }

  .containerBottomBar {
    flex-direction: column;
  }

  .containerTextBottomBar {
    margin-left: 67px;
    margin-right: 60px;
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
    padding-bottom: 10px;
  }

  .titleBottomBarPolice {
    font-size: 14px;
    text-align: center;
  }

  .containerTopShelf-loading {
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 16px;
  }

  .containerTopShelf {
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 16px;
  }

  .backgroundShelf {
    height: 170px;
  }

  .titleShelf {
    width: 100%;
    font-size: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .policeInfoShelf {
    font-size: 10px;
    line-height: 12px;
  }

  .followButton {
    width: 92px;
    height: 28px;
  }

  .nameOwnerPolice {
    font-size: 12px;
  }

  .roundPictureProfil {
    height: 24px;
    width: 24px;
  }

  .nbFollowersPolice {
    font-size: 12px;
    line-height: 14px;
  }

  .ownerShelf {
    margin-left: 16px;
    margin-top: 13px;
    height: 24px;
  }

  .containerDescShelf {
    padding: 7px 16px;
  }

  .likeComPolice {
    font-size: 10px;
  }

  .paddingLikeComment {
    padding-right: 25px;
  }

  .descShelfPolice {
    font-size: 14px;
  }

  .workPicture {
    height: 106px;
    width: 74px;
  }

  .workPicture-default {
    height: 106px;
    width: 74px;
  }

  .shelf-book-container {
    padding-right: 15px;
    padding-bottom: 12px;
  }

  .containerListBook {
    padding-left: 16px;
  }

  .backgroundOpacityListBookInShelf {
    width: 100%;
    opacity: 0.1;
  }

  .roundPictureProfilBottomBar {
    margin-top: 16px;
    margin-bottom: 10px;
    height: 54px;
    width: 54px;
  }

  .textBottomBarPolice {
    text-align: center;
    font-size: 12px;
  }

  .containerProfile {
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .container {
    width: 100%;
    height: 152px;
  }

  .backgroundTopBook {
    height: 152px;
  }

  .sizeBookCover {
    width: 84px;
    height: 120px;
  }

  .containerTopBook {
    padding: 16px 16px;
  }

  .infoBookPolice {
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .typeBookPolice {
    font-size: 12px;
    line-height: 14px;
  }

  .containerInfoBook {
    padding-left: 15px;
    padding-top: 0px;
  }

  .containerProcurer {
    top: 177px;
    padding-right: 16px;
  }

  .circleProcurer {
    height: 50px;
    width: 50px;
  }

  .containerAuthorPanel {
    padding: 12px 16px;
  }

  .authorNamePolice {
    font-size: 16px;
    line-height: 25.6px;
  }

  .publishYearPolice {
    font-size: 16px;
    line-height: 20.06px;
  }

  .separateLine {
    width: auto;
    margin-left: 16px;
    margin-right: 16px;
  }

  .containerPopularity {
    padding: 15px 6vw;
  }

  .positionPopularity {
    padding-bottom: 15px;
  }

  .containerTag {
    padding: 0px 0px;
  }

  .circleTag {
    border: 1.03px solid;
    height: 40px;
    width: 40px;
  }

  .nbTagPolice {
    font-size: 10px;
    line-height: 12px;
  }

  .nbTag {
    border: 1.03px solid #f7f7f7;
    height: 16px;
    min-width: 26px;
    margin-left: 20px;
    top: 295px;
  }

  .containerTextTag {
    min-width: 54px;
    padding-top: 12px;
  }

  .textTagsPolice {
    font-size: 12px;
    line-height: 14.4px;
  }

  .popularityPolice {
    font-size: 14px;
    line-height: 16.8px;
  }

  .resumeTitlePolice {
    font-size: 14px;
    line-height: 17px;
  }

  .resumePolice {
    font-size: 14px;
  }

  .ReadMorePolice {
    font-size: 14px;
    line-height: 19.32px;
  }

  .modal {
    border-radius: 8px;
    max-width: 270px;
    padding: 0px 32px;
    width: auto;
    height: auto;
  }

  .modal-container {
    padding-top: 14px;
  }

  .modal-header-police {
    font-size: 18px;
  }

  .modal-header-container {
    padding-top: 11px;
    padding-bottom: 12px;
  }

  .modal-body-police {
    font-size: 14px;
  }

  .modal-container-store {
    display: none;
  }

  .modal-container-form {
    padding: 0px 0px;
  }

  .modal-container-textfield {
    display: none;
  }

  .modal-submit-button {
    padding-right: 48px;
    padding-left: 43px;
    max-width: 220px;
    width: auto;
    margin-bottom: 17px;
  }

  .containerAllTags {
    justify-content: space-evenly;
  }

  .backgroundContent {
    height: auto;
  }

  .bookScreen-authorLog-Police {
    height: 18px;
    width: 18px;
  }

  .topshelf-info-nbbook {
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .topshelf-info-public {
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .topshelf-info-collab {
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .topshelf-icon-nbbook {
    margin-right: 1vw;
  }

  .topshelf-icon-public {
    padding-right: 1vw;
  }

  .containerResume {
    padding: 0px 16px;
    padding-top: 15px;
  }

  .bookscreen-peoplefollow-listpeople-container {
    padding-bottom: 16px;
    padding-top: 22px;
    padding-right: 16px;
  }

  .bookscreen-peoplefollow-picture-style {
    height: 62.5px;
    width: 62.5px;
  }

  .bookscreen-peoplefollow-nickname-police {
    font-size: 14px;
    line-height: 20px;
  }

  .bookscreen-peoplefollow-allcard-container {
    padding-left: 16px;
  }

  .bookscreen-comment-title-container {
    padding: 0px 16px;
    padding-top: 13px;
  }
  .bookscreen-comment-icon-style {
    height: 24px;
    width: 24px;
  }
  .bookscreen-similarbook-allcard-container {
    padding-left: 16px;
  }

  .bookscreen-comment-listcomment-container {
    padding-right: 16px;
    margin-bottom: 25px;
  }
  .bookscreen-comment-card-container {
    padding: 0px 16px;
    padding-top: 16px;
    padding-bottom: 10px;
  }
  .bookscreen-comment-picture-style {
    height: 40px;
    width: 40px;
  }
  .bookscreen-comment-author-police {
    font-size: 14px;
  }
  .bookscreen-comment-date-police {
    font-size: 14px;
  }
  .bookpeople-comment-tag-container {
    height: 20px;
    width: 20px;
  }
  .bookpeople-comment-tags-style {
    height: 9px;
  }
  .bookscreen-comment-content-container {
    max-width: 213px;
  }
  .bookscreen-comment-content-title-police {
    font-size: 18px;
    line-height: 132%;
  }
  .bookscreen-comment-content-police {
    font-size: 14px;
  }

  .bookscreen-comment-footer-police {
    font-size: 14px;
    line-height: 17px;
  }
  .bookscreen-comment-footer-like {
    width: 21px;
    height: 18px;
  }
  .bookscreen-comment-footer-comment {
    width: 18.5px;
    height: 17.5px;
  }
  .bookscreen-comment-footer-space {
    padding-left: 7px;
    padding-right: 13px;
    padding-top: 5px;
  }
  .bookscreen-shelf-icon-container {
    width: 24px;
    height: 24px;
  }
  .bookscreen-shelf-listshelf-container {
    margin-top: 13px;
  }
  .bookscreen-shelf-card-container {
    height: 160px;
    width: 312px;
  }
  .bookscreen-shelf-header-backgroundpicture {
    height: 104px;
    width: 312px;
    border-radius: 4.16667px 4.16667px 0px 0px;
  }
  .bookscreen-shelf-header-container {
    padding: 0px 10px;
    padding-top: 12px;
    padding-bottom: 4px;
  }
  .bookscreen-shelf-title-police {
    font-size: 13px;
    line-height: 16px;
  }
  .bookscreen-shelf-info-police {
    font-size: 11px;
    line-height: 14px;
  }
  .bookscreen-shelf-picture-style {
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  .bookscreen-shelf-info-container {
    padding-top: 7px;
  }
  .bookscreen-shelf-allcard-container {
    padding-left: 10px;
  }
  .bookscreen-shelf-listbook-container {
    padding-right: 10.4px;
  }
  .bookscreen-shelf-cover-style {
    height: 89px;
    width: 61px;
  }
  .bookscreen-sameauthor-title-container {
    padding-left: 16px;
    padding-top: 14px;
  }
  .bookscreen-similarbook-cover-style {
    height: 100px;
    width: 70px;
  }
  .bookscreen-similarbook-title-container {
    padding-left: 16px;
    padding-top: 14px;
  }

  .titleShelf-loading-up {
    border-bottom: 16px solid #ffffff;
    width: 60vw;
    margin-bottom: 12px;
  }
  .titleShelf-loading-down {
    opacity: 0.75;
    border-bottom: 16px solid #ffffff;
    width: 45vw;
    margin-bottom: 12px;
  }
  .titleBook-loading-up {
    border-bottom: 16px solid #ffffff;
    width: 40vw;
    margin-bottom: 12px;
  }
  .titleBook-loading-down {
    border-bottom: 16px solid #ffffff;
    width: 30vw;
    margin-bottom: 10px;
  }
  .bookscreen-cover-loading {
    height: 30px;
    width: 41.8px;
  }
  .backgroundbook-police-loading {
    width: 20vw;
    margin-bottom: 2px;
  }
  .bookScreen-authorLog-container {
    height: 28px;
    width: 28px;
    margin-right: 10px;
  }

  .commentScreen-header{
    display: flex;
  }

  .comment-header-picture {
    height: 40px;
    width: 40px;
  }

  .comment-footer-stats {
    font-size: 10px;
  }
}

@media (max-width: 352px) {
  .containerTextBottomBar {
    max-width: calc(100% - 30vw);
    width: auto;
    margin: 0px;
  }
}

@media (max-width: 318px) {
  .nbTag {
    top: 313px;
  }
}
