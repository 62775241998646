html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0} /* END RESET */

html {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 16px;
}

body {
    height: 100%;
    height: 100%;
    margin: 0;
}

/* NAVBAR */

nav {
    width: 100%;
    height: 80px;
    position: fixed;
    top: -80px;
    left: 0;
    z-index: 9999;
    background: #252525;
    transition: All cubic-bezier(0.19, 1, 0.22, 1) 500ms;
}

nav.onpage {top: 0; opacity: 1;}

#navcontainer {
    width: 900px;
    height: 100%;
    margin: auto;
    position: relative;
    text-align: right;
    vertical-align: top;
}

#navlogo {
    width: 135px;
    height: 40px;
    background: url('images/logosimple.svg') no-repeat;
    background-position: center center;
    background-size: 100% auto;
    position: absolute;
    left: 0;
    top: 20px;
}

#navlogo:hover {opacity: .7;}

#navcontainer div a {
    display: inline-block;
    width: 100%;
    height: 100%;
}

#androidstore {
    width: 25px;
    height: 40px;
    margin-top: 20px;
    background: url('images/android.svg') no-repeat;
    background-position: center center;
    background-size: 100% auto;
    display: inline-block;
}

#separator {
    width: 1px;
    height: 20px;
    background: #929292;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0px 20px;
    margin-top: 30px;
}

#appstore {
    width: 23px;
    height: 40px;
    margin-top: 20px;
    background: url('images/appstore.svg') no-repeat;
    background-position: center center;
    background-size: 100% auto;
    display: inline-block;
}

#navlogo, #appstore, #androidstore {
    transition: opacity ease 400ms;
}

#appstore:hover, #androidstore:hover {
    opacity: .7;
}


/* LANDING */

section {
    width: 100%;
    height: 550px;
    position: relative;
    overflow: hidden;
    border-top: 1px solid rgba(0,0,0,.1);
}

.containerhome {
    width: 900px;
    height: 100%;
    margin: auto;
    position: relative;
}

#landing {
    width: 100%;
    height: 100%;
    background: #F7F7F7;
    position: relative;
    border: none;
}

#black {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    background: #252525;
}

#logo {
    width: 275px;
    height: 107px;
    position: absolute;
    right: 145px;
    bottom: 210px;
    background: url(images/logofull.svg) no-repeat;
    background-size: 100% auto;
    background-position: center center;
}

#landing .containerhome {
    position: relative;
    z-index: 999;
}

#featurephone {
    height: 570px;
    width: 263px;
    display: inline-block;
    position: relative;
    box-shadow: 0px 0px 20px rgba(0,0,0,.25);
    border-radius: 50px;
    margin-left: 62px;
    vertical-align: top;
}

#featurephone #bg {
    width: 267px;
    height: 570px;
    background: url(images/iphone.svg) no-repeat;
    background-size: 100% auto;
    position: absolute;
    top: 0;
    left: -2px;
    z-index: 0;
}

#featurephone .slider {
    width: 240px;
    height: 427px;
    position: relative;
    z-index: 1;
    top: 70px;
    left: 12px;
    overflow: hidden;
}

#featurephone .slider img {
    width: 100%;
    height: 100%;
}

.slick-prev, .slick-next, .slick-arrow {display: none !important;}


#cartouche {
    max-width: 550px;
    display: block;
    margin: auto;
    margin-top: 80px;
    text-align: center;
}

#cartouche p {
    width: 100%;
    font-family: 'Fira Sans', Fira, sans-serif;
    font-weight: 300;
    line-height: 1.6;
    font-size: 1.2em;
}

#cartouche p span {
    font-family: 'Vollkorn', Vollkorn, 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    font-weight: normal;
    font-size: 1.5em;
}

.badge {
    width: 135px;
    height: 40px;
    margin-top: 40px;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: absolute;
}

.badge:hover {opacity: .7;}

.badge a {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.appstorebadge {background-image: url(images/appstorebadge_home.png); right: 300px;}
.playstorebadge {background-image: url(images/playstorebadge_home.png); margin-left: 25px; right: 130px;}


#arrow {
    width: 22px;
    height: 30px ;
    position: absolute;
    right: 272px;
    opacity: .2;
    background: url(images/arrow.svg) no-repeat;
    background-size: 100% 100%;
    animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    -moz-animation: bounce 3s infinite;
    -o-animation: bounce 3s infinite;
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
    40% {-webkit-transform: translateY(-20px);}
    60% {-webkit-transform: translateY(-15px);}
}

@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
    40% {-moz-transform: translateY(-20px);}
    60% {-moz-transform: translateY(-15px);}
}

@-o-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
    40% {-o-transform: translateY(-20px);}
    60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-20px);}
    60% {transform: translateY(-15px);}
}

/* FEATURES */

section .containerhome .feature {
    width: 363px;
    height: 680px;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
}

.containerhome.left .feature {
    top: -180px;
    right: -32px;
}

.containerhome.right .feature {
    bottom: -180px;
    left: -32px;
}

.containerhome .featblock {
    display: inline-block;
    width: 55%;
}

.containerhome .featblock .icon {
    width: 45px;
    height: 45px;
    background-position: center center;
    background-size: auto 100%;
    opacity: .5;
    margin-bottom: 20px;
}

.containerhome .featblock p span {
    font-family: 'Vollkorn', Vollkorn, 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    font-size: 1.45em;
}

.containerhome .featblock p {
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.2em;
    font-weight: 300;
    line-height: 1.4;
}

#scan {
    background: #fff;
}

#scan .feature {
    width: 500px;
    height: 700px;
    top: -202px;
    background-image: url(images/scan.gif);
    right: -203px;
}

#scan .featblock {margin-top: 130px;}
#scan .featblock .icon {background-image: url(images/scan.svg);}

#profile {
    background: #F7F7F7;
}

#profile .feature {
    width: 370px;
    height: 620px;
    bottom: -110px;
    left: -53px;
    background-image: url(images/profile.gif);
}

#profile .featblock {margin-top: 150px; margin-left: 380px;}
#profile .featblock .icon {background-image: url(images/profile.svg);}


#actus {
    background: #fff;
}

#actus .feature {
    width: 370px;
    height: 620px;
    top: -104px;
    right: -54px;
    background-image: url(images/actus.gif);
}
#actus .featblock {margin-top: 160px;}
#actus .featblock .icon {background-image: url(images/actus.svg);}

#achat {
    background: #F7F7F7;
}

#achat .feature {
    width: 370px;
    height: 620px;
    bottom: -110px;
    left: -54px;
    background-image: url(images/achat.gif);
}
#achat .featblock {margin-top: 160px; margin-left: 380px;}
#achat .featblock .icon {background-image: url(images/achat.svg);}

#mobilefeatures {display: none;}


/* END */

#end {
    background: #fff;
    height: 400px;
}

#end p {
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.7em;
    line-height: 1.6;
    font-weight: 300;
    text-align: center;
    margin: auto;
    margin-top: 155px;
    vertical-align: middle;
    width: 90%;
}

#end p a {
    text-decoration: none;
    font-weight: 400;
    color: #252525;
}

#end p a:hover {
    opacity: .7;
}


/* FOOTER */

footer {
    background: #252525;
    padding-top: 70px;
    padding-bottom: 100px;
    color: #fff;
}

#glp {
    width: 55px;
    height: 40px;
    background: url(images/logo.svg) no-repeat;
    background-size: 100% auto;
    background-position: center center;
    margin: auto;
    margin-bottom: 70px;
}

footer ul {
    width: 22%;
    margin: 0;
    margin-left: 2%;
    display: inline-block;
    vertical-align: top;
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.1em;
    line-height: 2em;
}


footer ul li:first-child {
    font-weight: 400;
}

footer ul li {font-weight: 300;}

footer ul li a {display: inline;}

footer ul li a, footer ul li a:visited {
    color: #fff;
    text-decoration: none;
    opacity: .7;
}

footer ul li a:hover, footer ul li a:active {
    opacity: 1;
}


/* PAGES */

section.onpage {
    padding-top: 80px;
    padding-bottom: 80px;
    height: auto;
    border: none;
    background: #F7F7F7;
}

section.onpage .pageheader {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-bottom: 70px;

}

section.onpage h1 {
    font-family: 'Vollkorn', serif;
    font-size: 3em;
    line-height: 1.6;
    text-align: center;
    max-width: 650px;
    margin: auto;
}

section.onpage p, section.onpage li{
    font-size: 1.1rem;
    font-family: 'Fira sans', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    width: 75%;
    margin: auto;
}

section.onpage p span {
    font-weight: 400;
}

section.onpage i {font-style: italic;}

section.onpage p a, section.onpage p a:active, section.onpage p a:hover, section.onpage p a:focus, section.onpage p a:visited  {color: #252525;}
section.onpage p a:hover {text-decoration: none;}

section.onpage strong {font-weight: 400;}

section.onpage li:before {
    content: "- ";
}

section.onpage li ul li:before {
    content: "• ";
}

section.onpage li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

/* 900px BREAKPOINT */
@media only screen and (max-width: 900px) {
    html {font-size: 1rem;}

    #navcontainer {width: 100%;}
    #navlogo  {left: 40px;}
    #androidstore {margin-right: 40px;}

    .containerhome {width: 100% !important;}
    section:not(#landing):not(#end):not(.onpage) { display: none;}
    #featurephone {display: block; margin: auto; margin-top: 250px;}
    #black {height: 360px !important;}
    #logo {position: relative; right: 0; top:0; width: 240px; height: 100px; margin: auto; margin-top: 100px;}
    #black .badge {display: none !important;}
    #landing {height: auto; padding-bottom: 60px;}
    #landing .containerhome {text-align: center;}
    #cartouche {margin: auto; margin-top: 80px !important;}
    #cartouche p {text-align: center;}
    #cartouche .badge {display: inline-block; margin-top: 80px; position: relative; position: relative !important; right: 0 !important;}
    #arrow{position: relative; right: 0; margin: auto; margin-top: 50px !important;}
    #mobilefeatures {display: block !important; margin: 0; width: 100%; height: 216vw; background: url(images/onboarding.png) no-repeat; background-size: 100%;}
    #end p {font-size: 1.3em; margin-top: 160px;}
    footer {padding-left: 50px; padding-right: 50px;}
    footer ul {width: 49%; margin-top: 20px;}

    section.onpage h1 {font-size: 2em;}
}


/* MOBILE VERSION */

@media only screen and (max-device-width: 900px) {
    html {font-size: 1rem;}

    nav {height: 150px; top: -150px;}
    #navcontainer {width: 100%;}
    #navlogo {left: 50px; width: 20%; height: 100%; top: 0 !important; }
    .dl {height: 100% !important;}
    #appstore {margin-top: 0; width: 38px !important;}
    #androidstore {margin-top: 0; margin-right: 50px; width: 40px !important;}
    .onpage #appstore {margin-top: 45px; height: 50px;}
    #separator {height: 40px !important; margin-top: 55px !important;}

    .containerhome {width: 100% !important;}
    section:not(#landing):not(#end):not(.onpage) { display: none;}
    #featurephone {display: block; margin: auto; margin-top: 500px; width: 526px; height: 1140px; border-radius: 90px;}
    #featurephone #bg {width: 534px; height: 1140px;}
    #featurephone .slider {width: 480px; height: 854px; top: 140px; left: 25px;}
    #black {height: 750px !important;}
    #logo {position: relative; right: 0; top:0; width: 45%; height: 630px; margin: auto;}
    #black .badge {display: none !important;}
    #landing {height: auto; padding-bottom: 120px;}
    #landing .containerhome {text-align: center;}
    #cartouche {margin: auto; margin-top: 130px !important; width: 65%;}
    #cartouche p {font-size: 2em;}
    #cartouche .badge {display: inline-block; width: 45%; height: 120px; background-position: center bottom; margin-top: 100px; position: relative !important; right: 0 !important;}
    .playstorebadge {margin-left: 9%;}
    #arrow{display: none;}
    #mobilefeatures {display: block !important; margin: 0; width: 100%; height: 216vw; background: url(images/onboarding.png) no-repeat; background-size: 100%;}
    #end p {margin-top: 155px;}
    footer {padding-left: 100px; padding-right: 100px;}
    footer ul {width: 49%; margin-top: 20px;}

    section.onpage {padding-top: 150px !important;}
    section.onpage h1 {font-size: 2em;}
}

p strong {font-weight: 500;}
